.InputDropdown {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.InputDropdown-select {
  font-size: inherit;
  background-color: white;
  border: none;
  min-width: 6em;
}
