.InputTextarea {
  white-space: pre-wrap;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
}

.InputTextarea-textarea {
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  margin-top: .6em;
}
