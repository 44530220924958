.Button {
  box-sizing: border-box;
  padding-top: .3em;
  padding-bottom: .3em;
  padding-left: .6em;
  padding-right: .6em;
  display: flex;
  justify-content: center;
  align-items: center;

  color: inherit;
  text-decoration: none;
}

.Button-outlined {
  border: 2px solid white;
}

.Button-no-padding {
  padding: 0;
}

.Button-rounded {
  border-radius: 999px;
}
