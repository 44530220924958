.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  /*background-color: #282c34;*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: calc(var(--vh) * 100);
  font-family: Signika;
}

.App-logo {
  max-height: max(4em, 18vmin);
  height: 154px;
  padding-top: 16px;
  padding-bottom: 16px;
  pointer-events: none;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(1rem + 1.2vmin);
  color: white;
  padding-left: 5%;
  padding-right: 5%;
}

.App-body {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  overflow: auto;
  font-size: calc(1rem + 1.2vmin);
  color: white;
  padding: 2%;
}

.App-contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.App-contact-us p {
  margin: .2em;
}

/* for facebook icon */
.fa {
  margin: .2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  width: 1.8em;
  height: 1.8em;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
.fa:hover {
  opacity: 0.7;
}
.fa-facebook {
  background: #3B5998;
  color: white;
}
