.InputRadioOption-row {
  margin-left: .6em;
  margin-bottom: .1em;
  text-align: left;
}

.InputRadioOption-label {
  user-select: none;
  text-align: left;
  padding-left: .3em;
}
