.Loader {
    border: .16em solid transparent;
    border-top-width: .16em;
    border-top-style: solid;
    border-top-width: .16em;
    border-top-style: solid;
    border-radius: 50%;
    width: 24px; /* fuck it just gonna hard code it*/
    height: 24px;
    box-sizing: border-box;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }