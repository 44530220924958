.InputRadio {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.InputRadio-label {
  margin-top: 0;
  margin-bottom: .2em;
  text-align: left;
}
