.Home {
  box-sizing: border-box;
  height: fit-content;
  max-width: 50vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
}

.Home-text {
  max-width: min(600px, 100%);
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
}

.Home-button {
  max-width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  padding-left: 1em;
  padding-right: 1em;
}
