.RequestQuote {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
}

.RequestQuote-form {
  display: flex;
  flex: 1;
  max-width: min(600px, 100vw);
  font-size: 1em;
  padding: 1em;
  padding-top: .6em;
  box-sizing: border-box;
  backdrop-filter: blur(8px);
}

.RequestQuote-form-contents {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.RequestQuote-form-contents-header {
  margin-bottom: .6em;
}

.RequestQuote-form-contents-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.RequestQuote-input-row {
  margin-bottom: 1em;
}

.RequestQuote-submit-row {
  display: flex;
  justify-content: flex-end;
  padding-top: .5em;
}

.RequestQuote-button {
  margin-left: 1em;
  width: 5em;
}

.RequestQuote-input-label {
  text-align: left;
}

.RequestQuote-input-text {
  box-sizing: border-box;
  min-width: 322px;
  font-size: inherit;
  font-family: inherit;
}

.RequestQuote-InputRadio-other {
}

.RequestQuote-form-submit-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
