.InputText {
  display: inline;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Input-contents {

}

.InputText-input {
  box-sizing: border-box;
  width: min(100%, 333px);
  font-size: inherit;
  font-family: inherit;
}
